import Admonition from "..\\..\\..\\..\\src\\components\\admonition";
import { M, I, J, S, X, C, P, Balance_i, Transfer_ij, Transfer_ps, Consumers_i, Suppliers_i, TransferVisual1, TransferVisual2, SupplierConstraints, ConsumerConstraints, PipeConstraints, FixedTransferConstraints, VariableTransferConstraints, SupplyAsMuchAsPossibleWeakConstraint, SupplyAsMuchAsNecessaryWeakConstraint } from "..\\..\\..\\..\\content\\posts\\2019-06-25-budgeteer\\math";
import * as React from 'react';
export default {
  Admonition,
  M,
  I,
  J,
  S,
  X,
  C,
  P,
  Balance_i,
  Transfer_ij,
  Transfer_ps,
  Consumers_i,
  Suppliers_i,
  TransferVisual1,
  TransferVisual2,
  SupplierConstraints,
  ConsumerConstraints,
  PipeConstraints,
  FixedTransferConstraints,
  VariableTransferConstraints,
  SupplyAsMuchAsPossibleWeakConstraint,
  SupplyAsMuchAsNecessaryWeakConstraint,
  React
};